.root {
  padding: 100px;
  max-width: 750px;
  margin: 0 auto;
  text-align: center;

  span {
    font-size: 80px;
  }
}

.description {
  font-size: 20px;
  margin-top: 25px;
}