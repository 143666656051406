.root {
  position: relative;
  left: -70px;
}

.input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 12px 12px 42px;
  width: 400px;
  height: 51px;
  border-radius: 10px;
  font-size: 16px;


  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.searchIcon {
  width: 28px;
  height: 28px;
  opacity: 0.7;
  position: absolute;
  left: 12px;
  top: 12px;
}

.clearIcon {
  width: 28px;
  height: 28px;
  opacity: 0.3;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}