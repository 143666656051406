.root {
  li {
    display: inline-block;


  }

  a {
    text-align: center;
    display: inline-block;
    justify-content: center;
    align-items: center;
    color: #fe5f1e;
    width: 45px;
    height: 45px;
    line-height: 42px;
    border: 1px solid #fe5f1e;
    border-radius: 30px;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f6a181;
      border: 1px solid #f6a181;
      color: #fff;
    }
  }

  :global {
    .selected {
      a {
        background-color: #fe5f1e;
        color: #fff;
      }
    }
  }

}